<template>
  <div class="VModalSocials">
    <div
      class="VModalSocials__overlay"
      @click="toggleModal"
    />
    <div class="VModalSocials__content">
      <div
        class="VModalSocials__close"
        @click="toggleModal"
      >
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <rect
            x="0.5"
            y="0.5"
            width="23"
            height="23"
            rx="11.5"
            fill="#101015"
          />
          <path
            d="M16 8L8 16M8 8L16 16L8 8Z"
            stroke="white"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <rect
            x="0.5"
            y="0.5"
            width="23"
            height="23"
            rx="11.5"
            stroke="#141517"
          />
        </svg>
      </div>
      <div class="VModalSocials__title">
        Присоединяйтесь к нам <br>
        в социальных сетях
      </div>
      <ul class="VModalSocials__list">
        <!--        <li class="VModalSocials__item">-->
        <!--          <a-->
        <!--            href="https://instagram.com/tapper.cloud"-->
        <!--            target="_blank"-->
        <!--          >-->
        <!--            <svg-->
        <!--              class="VModalSocials__ico"-->
        <!--              viewBox="0 0 49 48"-->
        <!--              fill="none"-->
        <!--              xmlns="http://www.w3.org/2000/svg"-->
        <!--            >-->
        <!--              <g clip-path="url(#clip0_402_4428)">-->
        <!--                <path-->
        <!--                  d="M24.5 48C37.7548 48 48.5 37.2548 48.5 24C48.5 10.7452 37.7548 0 24.5 0C11.2452 0 0.5 10.7452 0.5 24C0.5 37.2548 11.2452 48 24.5 48Z"-->
        <!--                  fill="url(#paint0_linear_402_4428)"-->
        <!--                />-->
        <!--                <path-->
        <!--                  d="M31.3749 39H17.6251C13.145 39 9.5 35.3554 9.5 30.8749V17.1251C9.5 12.6446 13.145 9 17.6251 9H31.3749C35.8554 9 39.5 12.6446 39.5 17.1251V30.8752C39.5 35.3554 35.8554 39 31.3749 39ZM17.6251 11.5001C14.5235 11.5001 12.0001 14.0235 12.0001 17.1251V30.8752C12.0001 33.9769 14.5235 36.5002 17.6251 36.5002H31.3749C34.4765 36.5002 36.9999 33.9769 36.9999 30.8752V17.1251C36.9999 14.0235 34.4765 11.5001 31.3749 11.5001H17.6251Z"-->
        <!--                  fill="white"-->
        <!--                />-->
        <!--                <path-->
        <!--                  d="M24.5 31.5C20.3645 31.5 17 28.1351 17 24C17 19.8641 20.3645 16.5 24.5 16.5C28.6355 16.5 32 19.8641 32 24C32 28.1351 28.6355 31.5 24.5 31.5ZM24.5 19.0001C21.743 19.0001 19.5001 21.243 19.5001 24C19.5001 26.7574 21.743 28.9999 24.5 28.9999C27.257 28.9999 29.4999 26.7574 29.4999 24C29.4999 21.243 27.2574 19.0001 24.5 19.0001Z"-->
        <!--                  fill="white"-->
        <!--                />-->
        <!--                <path-->
        <!--                  d="M32.4686 17.7497C33.4178 17.7497 34.1872 16.9803 34.1872 16.0311C34.1872 15.082 33.4178 14.3125 32.4686 14.3125C31.5195 14.3125 30.75 15.082 30.75 16.0311C30.75 16.9803 31.5195 17.7497 32.4686 17.7497Z"-->
        <!--                  fill="white"-->
        <!--                />-->
        <!--              </g>-->
        <!--              <defs>-->
        <!--                <linearGradient-->
        <!--                  id="paint0_linear_402_4428"-->
        <!--                  x1="10.7332"-->
        <!--                  y1="43.6615"-->
        <!--                  x2="38.2672"-->
        <!--                  y2="4.3389"-->
        <!--                  gradientUnits="userSpaceOnUse"-->
        <!--                >-->
        <!--                  <stop stop-color="#FFB900" />-->
        <!--                  <stop-->
        <!--                    offset="1"-->
        <!--                    stop-color="#9100EB"-->
        <!--                  />-->
        <!--                </linearGradient>-->
        <!--                <clipPath id="clip0_402_4428">-->
        <!--                  <rect-->
        <!--                    width="48"-->
        <!--                    height="48"-->
        <!--                    fill="white"-->
        <!--                    transform="translate(0.5)"-->
        <!--                  />-->
        <!--                </clipPath>-->
        <!--              </defs>-->
        <!--            </svg>-->
        <!--          </a>-->
        <!--        </li>-->
        <!--        <li class="VModalSocials__item">-->
        <!--          <a-->
        <!--            href="https://www.facebook.com/tapper.cloud/"-->
        <!--            target="_blank"-->
        <!--          >-->
        <!--            <svg-->
        <!--              class="VModalSocials__ico"-->
        <!--              viewBox="0 0 49 48"-->
        <!--              fill="none"-->
        <!--              xmlns="http://www.w3.org/2000/svg"-->
        <!--            >-->
        <!--              <g clip-path="url(#clip0_402_4398)">-->
        <!--                <path-->
        <!--                  d="M24.5 48C37.7548 48 48.5 37.2548 48.5 24C48.5 10.7452 37.7548 0 24.5 0C11.2452 0 0.5 10.7452 0.5 24C0.5 37.2548 11.2452 48 24.5 48Z"-->
        <!--                  fill="#3F65A6"-->
        <!--                />-->
        <!--                <path-->
        <!--                  d="M20.8531 16.1063C20.8531 16.8001 20.8531 19.8938 20.8531 19.8938H18.0781V24.5251H20.8531V37.2188H26.5531V24.5157H30.3781C30.3781 24.5157 30.7344 22.2938 30.9125 19.8657C30.4156 19.8657 26.5812 19.8657 26.5812 19.8657C26.5812 19.8657 26.5812 17.1751 26.5812 16.697C26.5812 16.2282 27.2 15.5907 27.8187 15.5907C28.4281 15.5907 29.7219 15.5907 30.9219 15.5907C30.9219 14.9626 30.9219 12.7782 30.9219 10.772C29.3187 10.772 27.5 10.772 26.7031 10.772C20.7125 10.7813 20.8531 15.4126 20.8531 16.1063Z"-->
        <!--                  fill="white"-->
        <!--                />-->
        <!--              </g>-->
        <!--              <defs>-->
        <!--                <clipPath id="clip0_402_4398">-->
        <!--                  <rect-->
        <!--                    width="48"-->
        <!--                    height="48"-->
        <!--                    fill="white"-->
        <!--                    transform="translate(0.5)"-->
        <!--                  />-->
        <!--                </clipPath>-->
        <!--              </defs>-->
        <!--            </svg>-->
        <!--          </a>-->
        <!--        </li>-->
        <li class="VModalSocials__item">
          <a
            href="https://t.me/tapper_cloud"
            target="_blank"
          >
            <svg
              class="VModalSocials__ico"
              viewBox="0 0 49 48"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g clip-path="url(#clip0_402_4405)">
                <path
                  d="M24.5 48C37.7548 48 48.5 37.2548 48.5 24C48.5 10.7452 37.7548 0 24.5 0C11.2452 0 0.5 10.7452 0.5 24C0.5 37.2548 11.2452 48 24.5 48Z"
                  fill="url(#paint0_linear_402_4405)"
                />
                <path
                  d="M9.43764 25.1739C12.2432 23.6285 15.375 22.3387 18.3012 21.0423C23.3354 18.9189 28.3896 16.8323 33.4948 14.8897C34.4881 14.5587 36.2728 14.235 36.4478 15.707C36.352 17.7906 35.9578 19.862 35.6874 21.9334C35.0011 26.4886 34.2079 31.0282 33.4344 35.5684C33.1679 37.0807 31.2734 37.8636 30.0612 36.8958C27.148 34.928 24.2124 32.9794 21.3364 30.966C20.3943 30.0087 21.2679 28.634 22.1093 27.9504C24.5087 25.5858 27.0533 23.5768 29.3273 21.09C29.9407 19.6087 28.1283 20.8571 27.5305 21.2396C24.2457 23.5032 21.0413 25.905 17.5781 27.8944C15.8091 28.8682 13.7473 28.036 11.9791 27.4926C10.3937 26.8362 8.07048 26.1749 9.43748 25.174L9.43764 25.1739Z"
                  fill="white"
                />
              </g>
              <defs>
                <linearGradient
                  id="paint0_linear_402_4405"
                  x1="18.5028"
                  y1="2.0016"
                  x2="6.5028"
                  y2="30"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop stop-color="#37AEE2" />
                  <stop
                    offset="1"
                    stop-color="#1E96C8"
                  />
                </linearGradient>
                <clipPath id="clip0_402_4405">
                  <rect
                    width="48"
                    height="48"
                    fill="white"
                    transform="translate(0.5)"
                  />
                </clipPath>
              </defs>
            </svg>
          </a>
        </li>
        <li class="VModalSocials__item">
          <a
            href="https://vk.com/tapper.cloud"
            target="_blank"
          >
            <svg
              class="VModalSocials__ico"
              viewBox="0 0 49 48"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M24.5 48C37.7548 48 48.5 37.2548 48.5 24C48.5 10.7452 37.7548 0 24.5 0C11.2452 0 0.5 10.7452 0.5 24C0.5 37.2548 11.2452 48 24.5 48Z"
                fill="#4D76A1"
              />
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M23.5943 34.526H25.478C25.478 34.526 26.047 34.4635 26.3375 34.1503C26.6049 33.8628 26.5963 33.3229 26.5963 33.3229C26.5963 33.3229 26.5595 30.7953 27.7326 30.4231C28.889 30.0565 30.374 32.866 31.948 33.9462C33.1382 34.7638 34.0426 34.5846 34.0426 34.5846L38.2511 34.526C38.2511 34.526 40.4526 34.3903 39.4088 32.6594C39.3232 32.5177 38.8008 31.3789 36.2797 29.0387C33.6409 26.5894 33.9942 26.9856 37.173 22.7488C39.1089 20.1686 39.8828 18.5934 39.6411 17.9187C39.4105 17.2761 37.9867 17.446 37.9867 17.446L33.2481 17.4755C33.2481 17.4755 32.8969 17.4276 32.6363 17.5833C32.3818 17.736 32.2179 18.092 32.2179 18.092C32.2179 18.092 31.4679 20.0886 30.4677 21.7867C28.3577 25.3697 27.514 25.5588 27.1692 25.3363C26.367 24.8178 26.5672 23.2532 26.5672 22.1418C26.5672 18.6696 27.0939 17.2218 25.5417 16.847C25.0266 16.7225 24.6476 16.6404 23.3303 16.6271C21.6396 16.6096 20.2085 16.6323 19.3982 17.0293C18.8591 17.2933 18.4433 17.8815 18.6966 17.9153C19.0097 17.9572 19.7191 18.1065 20.0951 18.6186C20.5807 19.2792 20.5636 20.7629 20.5636 20.7629C20.5636 20.7629 20.8425 24.8503 19.912 25.3581C19.2733 25.7064 18.3971 24.9953 16.516 21.7447C15.5521 20.0797 14.8243 18.2392 14.8243 18.2392C14.8243 18.2392 14.684 17.8952 14.4337 17.7112C14.13 17.4883 13.7056 17.4173 13.7056 17.4173L9.20276 17.4468C9.20276 17.4468 8.5268 17.4657 8.27866 17.7596C8.0579 18.021 8.26112 18.5617 8.26112 18.5617C8.26112 18.5617 11.7864 26.8093 15.778 30.9656C19.438 34.7762 23.5943 34.526 23.5943 34.526Z"
                fill="white"
              />
            </svg>
          </a>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: 'VModalSocials',
  emits: ['toggleModal'],
  methods: {
    toggleModal() {
      this.$emit('toggleModal')
    },
  },
}
</script>

<style lang="scss">
.VModalSocials {
  left: 0;
  top: 0;
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 9999;
  display: flex;
  align-items: center;
  justify-content: center;
  &__overlay {
    background: #212129;
    opacity: 0.95;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
  }
  &__content {
    position: relative;
    z-index: 2;
    background: #ffffff;
    border: 2px solid #2c3038;
    border-radius: 16px;
    width: 497px;
    height: 292px;
    padding-top: 78px;
    @media (max-width: 500px) {
      width: calc(100% - 40px);
      max-width: 350px;
      height: 200px;
      padding-top: 50px;
      border-radius: 9px;
    }
  }
  &__close {
    position: absolute;
    z-index: 3;
    right: 24px;
    top: 24px;
    width: 24px;
    height: 24px;
    cursor: pointer;
    @media (max-width: 500px) {
      right: 14px;
      top: 14px;
    }
  }
  &__title {
    color: #14141b;
    text-align: center;
    letter-spacing: -0.7px;
    font-size: 28px;
    line-height: 38px;
    font-family: 'Stolzl';
    margin-bottom: 32px;
    @media (max-width: 500px) {
      font-size: 18px;
      line-height: 130%;
      margin-bottom: 16px;
      letter-spacing: 0;
    }
  }
  &__list {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  &__item {
    margin: 0 12px;
    @media (max-width: 500px) {
      margin: 0 8px;
    }
  }
  &__ico {
    width: 48px;
    height: 48px;
    @media (max-width: 500px) {
      width: 32px;
      height: 32px;
    }
  }
}
</style>
