<template>
  <section
    id="questions"
    class="vLandingQuestions"
  >
    <div class="landing-container vLandingQuestions__container">
      <div class="vLandingQuestions__faq">
        <h2 class="vLandingQuestions__title landing-section-title">
          Вопросы и ответы
        </h2>
        <div class="vLandingQuestions__list-wrapper">
          <ul class="vLandingQuestions__list">
            <li
              v-for="item in questions"
              :key="item.id"
              :ref="'vLandingQuestionsItem' + item.id"
              class="vLandingQuestions__item"
              @click="toggleQuestions(item.id)"
            >
              <div class="vLandingQuestions__question">
                <p>{{ item.question }}</p>
                <span class="vLandingQuestions__close">+</span>
              </div>
              <div
                class="vLandingQuestions__answear"
                :style="{ maxHeight: activeEl == item.id ? hei + 'px' : 0 }"
              >
                <div class="vLandingQuestions__fake-padding" />
                {{ item.answear }}
              </div>
            </li>
          </ul>
          <ul
            class="vLandingQuestions__list vLandingQuestions__list--hidden-mobile"
            :class="{ active: showMore }"
          >
            <li
              v-for="item in questions2"
              :key="item.id"
              :ref="'vLandingQuestionsItem' + item.id"
              class="vLandingQuestions__item"
              @click="toggleQuestions(item.id)"
            >
              <div class="vLandingQuestions__question">
                <p>{{ item.question }}</p>
                <span class="vLandingQuestions__close">+</span>
              </div>
              <div
                class="vLandingQuestions__answear"
                :style="{ maxHeight: activeEl == item.id ? hei + 'px' : 0 }"
              >
                <div class="vLandingQuestions__fake-padding" />
                {{ item.answear }}
              </div>
            </li>
          </ul>
          <button
            v-if="!showMore"
            class="vLandingQuestions__showmore-btn"
            @click="toggleShowMore"
          >
            Смотреть больше
          </button>
        </div>
      </div>
      <div
        id="vLandingQuestions__callback"
        class="vLandingQuestions__callback"
      >
        <h2 class="vLandingQuestions__callback-title landing-section-title">
          Не нашли нужную информацию?
        </h2>
        <vLandingFeedback />
      </div>
    </div>
    <div class="vLandingQuestions__top-bg">
      <img
        src="~@/assets/images/bg_question_top.png"
        alt=""
        class="vLandingQuestions__bg-img mouse-parallax-bg"
      >
      <div class="vLandingQuestions__top-bg">
        <img
          src="~@/assets/images/bg_question_top_2.svg"
          alt=""
          class="vLandingQuestions__bg-img"
        >
      </div>
    </div>
    <div class="vLandingQuestions__bottom-bg">
      <div class="iphone-bg">
        <img
          src="~@/assets/images/bg_question_bottom.png"
          alt=""
          class="vLandingQuestions__bg-img iphone-bg-img mouse-parallax-bg"
        >
      </div>
      <div class="vLandingQuestions__bottom-bg">
        <img
          src="~@/assets/images/bg_question_bottom_2.svg"
          alt=""
          class="vLandingQuestions__bg-img"
        >
      </div>
    </div>
  </section>
</template>

<script>
import vLandingFeedback from '@/components/landing/v-landing-feedback'
export default {
  name: 'VLandingQuestions',
  components: {
    vLandingFeedback,
  },
  data() {
    return {
      name: '',
      phone: '',
      activeEl: 1,
      showMore: false,
      styleObject: {
        maxHeight: 0,
      },
      questions: [
        {
          id: 1,
          question: 'Какие обязательные требования для внедрения сервиса?',
          answear:
            'Жестких требований нет, у вас должна быть установлена одна из популярных систем управления заведением (например, r‑keeper или iiko), а также большое желание сделать ваше заведение удобнее для гостей:)',
        },
        {
          id: 2,
          question:
            'Зачем нужна интеграция с r‑keeper, iiko или другими сервисами?',
          answear:
            'Интеграция нужна для того, чтобы в Tapper подгрузилась информация о заказе гостей, а также, чтобы в вашу систему поступила информация о факте оплаты счета.',
        },
        {
          id: 3,
          question:
            'Как происходит внедрение Tapper? Сколько времени это займет?',
          answear:
            'На нашем сайте нажимаете на кнопку "Подключить заведение", далее вводите требующуюся информацию, затем выбираете, с какой системой уже работаете, после чего с вами свяжется наш менеджер и поможет организовать внедрение Tapper. Чтобы закрыть все вопросы, нам понадобится от 7 дней.',
        },
        {
          id: 4,
          question: 'Почему Tapper бесплатный?',
          answear:
            'Нам платит банк-эквайер небольшой процент с транзакций, так как Tapper стимулирует людей платить онлайн.',
        },
        {
          id: 5,
          question: 'Для каких устройств доступен Tapper?',
          answear:
            'Tapper поддерживается любыми мобильными устройствами с камерой и интернетом. Он моментально загружается в браузере при сканировании qr-кода и не требует никаких дополнительных установок.',
        },
      ],
      questions2: [
        {
          id: 6,
          question:
            'Как Tapper определяет, какой заказ относится к пользователю?',
          answear:
            'Для каждого стола мы генерируем уникальный qr-код, таким образом, система понимает, какой заказ, к какому столу относится.',
        },
        {
          id: 7,
          question: 'Какие способы оплаты есть в Tapper?',
          answear:
            'Можно оплатить любой удобной для вас картой, а также Apple Pay и Google Pay',
        },
        {
          id: 8,
          question: 'Можно ли производить оплату счета вне сервиса?',
          answear:
            'Подключение сервиса не обязывает вас принимать оплату только через сервис Tapper. Вы также сможете принимать оплату удобным для гостя способом.',
        },
        {
          id: 9,
          question: 'А если гости не захотят использовать Tapper?',
          answear:
            'Пользование сервисом Tapper для гостей опционально, мы ни к чему не обязываем.',
        },
        {
          id: 10,
          question: 'Собирает ли Tapper какие-то данные о заказах и гостях?',
          answear:
            'Tapper видит всю информацию по заказам гостей, но никак не использует и не распроcтраняет её.',
        },
      ],
    }
  },
  computed: {
    hei: function () {
      return this.styleObject.maxHeight
    },
  },
  mounted() {
    // Parallax
    let bg = document.querySelectorAll('.mouse-parallax-bg')
    for (let i = 0; i < bg.length; i++) {
      window.addEventListener('mousemove', function (e) {
        let x = e.clientX / window.innerWidth
        let y = e.clientY / window.innerHeight
        bg[i].style.transform =
          'translate(-' + x * 50 + 'px, -' + y * 50 + 'px)'
      })
    }
  },
  methods: {
    toggleShowMore() {
      this.showMore = true
    },
    toggleQuestions(id, e) {
      const el = this.$refs['vLandingQuestionsItem' + id]
      const allElements = document.querySelectorAll('.vLandingQuestions__item')
      const answearHeight = el.querySelector(
        '.vLandingQuestions__answear'
      ).scrollHeight
      if (el.classList.contains('active')) {
        allElements.forEach((i) => {
          i.classList.remove('active')
          this.styleObject.maxHeight = 0
        })
      } else {
        allElements.forEach((i) => {
          i.classList.remove('active')
        })
        el.classList.add('active')
        this.styleObject.maxHeight = answearHeight
      }
      this.activeEl = id
    },
  },
}
</script>

<style lang="scss">
.vLandingQuestions {
  position: relative;
  padding: 100px 100px;
  background: linear-gradient(94.19deg, #14141b 48.83%, #0c0c10 78.63%);
  border-bottom: 1px solid #2c3038;
  @media (max-width: 991px) {
    padding: 70px 0;
  }
  @media (max-width: 769px) {
    padding: 79px 0 56px;
    overflow-x: hidden;
  }
  &__container {
    position: relative;
    z-index: 3;
  }
  &__faq {
    position: relative;
    margin-bottom: 200px;
    z-index: 3;
    @media (max-width: 769px) {
      margin-bottom: 114px;
    }
  }
  &__title {
    margin-bottom: 56px;
    @media (max-width: 769px) {
      margin-bottom: 40px;
    }
  }
  &__list-wrapper {
    display: flex;
    justify-content: space-between;
    @media (max-width: 769px) {
      flex-direction: column;
    }
  }
  &__list {
    max-width: 48%;
    list-style-type: none;
    margin-bottom: 0;
    margin-top: 0;
    @media (max-width: 769px) {
      min-width: 100%;
    }
  }
  &__item {
    max-width: 552px;
    padding: 14px 24px;
    margin-bottom: 24px;
    font-family: 'PTRootUI';
    font-weight: 500;
    font-size: 18px;
    background: #fbfbfc;
    border: 2px solid #dfe4ea;
    border-radius: 16px;
    &.active .vLandingQuestions__answear {
      border-top: 1px solid #edeff4;
    }
    &.active .vLandingQuestions__close {
      transform: rotate(45deg);
      color: #c01718;
    }
  }
  &__question {
    display: flex;
    justify-content: space-between;
    align-items: center;
    min-height: 72px;
    line-height: 22px;
    color: #14141b;
    cursor: pointer;
  }
  &__close {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 30px;
    width: 30px;
    height: 30px;
    max-width: 30px;
    min-width: 30px;
    padding: 0 !important;
    font-size: 30px;
    border: 2.5px solid #31313c;
    border-radius: 100%;
    background-color: transparent;
    transition: transform 0.5s;
    cursor: pointer;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  }
  &__answear {
    line-height: 28px;
    color: #5a5a68;
    overflow: hidden;
    transition: all 0.7s;
  }
  &__fake-padding {
    width: 100%;
    height: 16px;
  }
  &__list--hidden-mobile {
    &.active {
      display: block !important;
    }
    @media (max-width: 769px) {
      display: none;
    }
  }
  &__showmore-btn {
    display: none;
    padding: 16px 28px;
    font-family: 'Stolzl';
    font-weight: 500;
    font-size: 20px;
    line-height: 28px;
    color: #fbfbfc;
    border: 2px solid #9c1313;
    border-radius: 8px;
    background: transparent;
    cursor: pointer;
    @media (max-width: 769px) {
      display: inline-block;
    }
  }
  p {
    @media (max-width: 991px) {
      margin-right: 15px;
    }
  }
  &__callback-title {
    position: relative;
    margin-bottom: 56px !important;
    z-index: 2;
    @media (max-width: 769px) {
      margin-bottom: 40px;
    }
  }
  &__top-bg {
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    z-index: 2;
    @media (max-width: 769px) {
      right: -120%;
    }
  }
  &__bg-img {
    position: sticky;
    top: 0;
  }
  &__bottom-bg {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    @media (max-width: 769px) {
      left: -120%;
    }
  }
  .iphone-bg {
    height: 1000px;
    position: sticky;
    top: 0;
    display: flex;
    align-items: flex-end;
  }
}
</style>
