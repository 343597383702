<template>
  <section class="vLandingDemoMobile">
    <img
      src="@/assets/images/Iphone-mob.gif"
      alt="Демо-версия Tapper"
      class="vLandingDemoMobile__demo-iphone-mobile"
    >
  </section>
</template>

<script>
export default {
  name: 'VLandingDemoMobile',
}
</script>

<style lang="scss">
.vLandingDemoMobile {
  display: none;
  padding: 25px 0;
  text-align: center;
  background: linear-gradient(94.19deg, #14141b 48.83%, #0c0c10 78.63%);
  @media (max-width: 769px) {
    display: block;
  }
  &__demo-iphone-mobile {
    width: 320px;
    @media (max-width: 991px) {
      width: 300px;
    }
    @media (max-width: 991px) {
      width: 50%;
    }
    @media (max-width: 576px) {
      width: 90%;
    }
  }
}
</style>
