<template>
  <Field
    v-slot="{ field, errorMessage }"
    :name="id"
    :rules="rule"
  >
    <div
      class="vLandingInput"
      :class="[
        { err: errorMessage || error.length },
        { emptyFieldError: required && !value.length },
      ]"
    >
      <div
        class="vLandingInput__wrapper"
        :class="{ disabled: disabled }"
      >
        <input
          v-if="id === 'phone'"
          v-bind="field"
          :id="id"
          v-maska="'+7(###) ###-##-##'"
          :value="value"
          :name="id"
          :type="type"
          class="vLandingInput__field"
          :class="{ active: value.length }"
          :required="required"
          :disabled="disabled"
          @input="onInput($event.target.value)"
        >
        <input
          v-else
          v-bind="field"
          :id="id"
          :value="value"
          :name="id"
          :type="showPassword ? 'text' : type"
          class="vLandingInput__field"
          :class="{ active: value.length }"
          :required="required"
          :disabled="disabled"
          @paste="onPaste"
          @input="onInput($event.target.value)"
        >
        <div class="vLandingInput__label">
          {{ placeholder }}
          <span
            v-if="required"
            class="vLandingInput__star"
          >*</span>
        </div>
        <svg
          v-if="type == 'password' && !showPassword"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          class="vLandingInput__eye"
          @click="toggleShowPassword"
        >
          <path
            d="M12 19C11.158 19 10.315 18.822 9.496 18.505L12 19ZM12 19C15.495 19 18.99 15.967 20.882 12.468C21.039 12.177 21.039 11.824 20.882 11.533C20.384 10.612 19.77 9.73 19.079 8.921M5 19L19 5M9.773 14.227C8.543 12.997 8.543 11.002 9.773 9.772C11.003 8.542 12.998 8.542 14.228 9.772"
            stroke="#80869A"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M17.0438 6.956C15.4968 5.759 13.7478 5 11.9998 5C8.50475 5 5.00975 8.033 3.11775 11.533C2.96075 11.824 2.96075 12.177 3.11775 12.468C4.06375 14.217 5.40975 15.849 6.95575 17.045"
            stroke="#80869A"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
        <svg
          v-if="type == 'password' && showPassword"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          class="vLandingInput__eye"
          @click="toggleShowPassword"
        >
          <path
            d="M3.11824 12.467C2.96124 12.176 2.96124 11.823 3.11824 11.532C5.01024 8.033 8.50524 5 12.0002 5C15.4952 5 18.9902 8.033 20.8822 11.533C21.0392 11.824 21.0392 12.177 20.8822 12.468C18.9902 15.967 15.4952 19 12.0002 19C8.50524 19 5.01024 15.967 3.11824 12.467V12.467Z"
            stroke="#80869A"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M14.1213 9.87868C15.2929 11.0502 15.2929 12.9497 14.1213 14.1213C12.9497 15.2929 11.0502 15.2929 9.87868 14.1213C8.70711 12.9497 8.70711 11.0502 9.87868 9.87868C11.0502 8.70711 12.9497 8.70711 14.1213 9.87868Z"
            stroke="#80869A"
            stroke-width="1.429"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
        <!-- <svg
          v-if="
            (errorMessage && type !== 'password') ||
              (error.length && type !== 'password')
          "
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          class="vLandingInput__invalid-icon"
        >
          <path
            d="M12 21C7.029 21 3 16.971 3 12C3 7.029 7.029 3 12 3C16.971 3 21 7.029 21 12C21 16.971 16.971 21 12 21Z"
            stroke="#EC4E4E"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M11.749 8C11.6772 8 11.6125 8.03028 11.5671 8.07874C11.5251 8.12349 11.4995 8.18375 11.5 8.25C11.5 8.29461 11.5117 8.3365 11.5322 8.37276C11.5751 8.4487 11.6566 8.5 11.75 8.5C11.8434 8.5 11.9249 8.4487 11.9678 8.37276C11.9883 8.3365 12 8.29461 12 8.25C12 8.17383 11.9659 8.10559 11.9119 8.05972C11.8681 8.02249 11.8113 8 11.749 8Z"
            fill="#C4C4C4"
          />
          <path
            d="M12 17V12H11M11.9119 8.05972C11.9659 8.10559 12 8.17383 12 8.25C12 8.29461 11.9883 8.3365 11.9678 8.37276M11.9119 8.05972C11.8681 8.02249 11.8113 8 11.749 8C11.6772 8 11.6125 8.03028 11.5671 8.07874M11.9119 8.05972L11.749 8.45312L11.5671 8.07874M11.5671 8.07874C11.5251 8.12349 11.4995 8.18375 11.5 8.25C11.5 8.29461 11.5117 8.3365 11.5322 8.37276M11.5322 8.37276C11.5751 8.4487 11.6566 8.5 11.75 8.5C11.8434 8.5 11.9249 8.4487 11.9678 8.37276M11.5322 8.37276L11.75 8.25L11.9678 8.37276"
            stroke="#EC4E4E"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg> -->
      </div>
      <div class="vLandingInput__err-empty">
        {{ parseInt(error.length) > 0 ? 'Поле не может быть пустым' : '' }}
      </div>
      <div class="vLandingInput__err">
        {{ parseInt(error.length) > 0 ? error : '' }}
        <br v-if="parseInt(error.length) > 0">
        <ErrorMessage :name="id" />
      </div>
    </div>
  </Field>
</template>

<script>
import { Field, ErrorMessage } from 'vee-validate'
import { defineRule, configure } from 'vee-validate'

configure({
  validateOnInput: true,
})

// Обязательное поле
defineRule('required', (value) => {
  if (value && value.trim()) {
    return true
  }
  return 'Поле не может быть пустым!'
})

// Только русские символы
defineRule('rus', (value) => {
  const regexp = /^[а-яА-ЯёЁ\s]+$/
  const res = regexp.test(value)
  if (res) {
    return true
  }
  return 'Имя может содержать только буквы А-Я'
})

// Телефон
defineRule('phone', (value) => {
  if (value.length === 17) {
    return true
  }
  return 'Некорректный номер телефона'
})

// email
defineRule('mail', (value) => {
  const regexp = /^\w.+@[a-zA-Z_].+?\.[a-zA-Z]{2,9}$/
  const res = regexp.test(value)
  if (res) {
    return true
  }
  return 'Введите корректный E-mail'
})

// Минимальное кол-во
defineRule('min', (value, args) => {
  if (value.length >= args[0]) {
    return true
  }
  return `Введите минимум ${args[0]} символов`
})

// Максимальное кол-во
defineRule('max', (value, args) => {
  if (value.length <= args[0]) {
    return true
  }
  return `Введите не более ${args[0]} символов`
})

// Проверка совпадения пароля
defineRule('confirmed', (value, [target]) => {
  if (value === target) {
    return true
  }
  return 'Пароли не совпадают'
})

export default {
  name: 'VLandingInput',
  components: {
    Field,
    ErrorMessage,
  },
  props: {
    placeholder: {
      type: String,
      require: true,
      default: '',
    },
    required: {
      type: Boolean,
      require: false,
      default: false,
    },
    id: {
      type: String,
      require: true,
      default: '',
    },
    value: {
      type: [String, Number],
      require: true,
      default: '',
    },
    type: {
      type: String,
      require: false,
      default: 'text',
    },
    rule: {
      type: String,
      require: false,
      default: '',
    },
    disabled: {
      type: Boolean,
      require: false,
      default: false,
    },
    error: {
      type: String,
      require: false,
      default: '',
    },
  },
  emits: ['update:modelValue'],
  data() {
    return {
      showPassword: false,
    }
  },
  methods: {
    onInput(val) {
      this.$emit('update:modelValue', val)
    },
    onPaste(evt) {
      if (this.id === 'confirmation' || this.id === 'password') {
        evt.preventDefault()
      }
    },
    toggleShowPassword() {
      this.showPassword = !this.showPassword
    },
  },
}
</script>

<style lang="scss">
.vLandingInput {
  padding: 0;
  margin: 0;
  margin-bottom: 20px;
  border: none;
  &.err {
    .vLandingInput__wrapper {
      border: 1px solid #ec4e4e;
      &::after {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
        background: #f8e0e0;
        z-index: 1;
      }
    }
  }
  &__wrapper {
    position: relative;
    border: 1px solid #dfe4ea;
    border-radius: 8px;
    &::after {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
      background: #fff;
      z-index: 1;
    }
  }
  &__field {
    width: 100%;
    padding: 14px 42px 10px 12px;
    background: transparent;
    border: none;
    font-family: 'PTRootUI';
    line-height: 28px;
    font-weight: 500;
    font-size: 18px;
    position: relative;
    z-index: 3;
    outline: none;
    &:focus + .vLandingInput__label,
    &.active + .vLandingInput__label {
      top: 12px;
      font-weight: 500;
      font-size: 12px;
      line-height: 22px;
      z-index: 2;
    }
    &:disabled {
      color: #353841;
    }
  }
  &__label {
    position: absolute;
    top: 50%;
    left: 12px;
    font-weight: 500;
    line-height: 28px;
    font-size: 18px;
    color: #5a5a68;
    transform: translateY(-50%);
    transition: all 0.3s;
    z-index: 2;
  }
  &__star {
    color: #c01718;
  }
  &__invalid-icon {
    position: absolute;
    top: 50%;
    right: 19px;
    transform: translateY(-50%);
    cursor: pointer;
  }
  &__eye {
    position: absolute;
    top: 50%;
    right: 19px;
    transform: translateY(-50%);
    cursor: pointer;
    z-index: 3;
  }
  &__err {
    padding-left: 12px;
    margin-top: 4px;
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    color: #ec4e4e;
  }
}

.emptyFieldError {
  // .vLandingInput__err {
  //   display: none;
  // }
  &.vLandingInput.err .vLandingInput__wrapper {
    border-color: #dfe4ea;
    &::after {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
      background: #fbfbfc;
      z-index: 1;
    }
  }
  .vLandingInput__invalid-icon {
    display: none;
  }
}
</style>
