<template>
  <div class="vLandingFeedback">
    <h3 class="vLandingFeedback__title landing-section-title">
      Закажите обратный звонок
    </h3>
    <Form
      class="vLandingFeedback__form"
      @submit="checkForm"
    >
      <!-- Имя -->
      <div
        class="vLandingFeedback__input-wrap"
        :class="{ active: name.length }"
      >
        <div
          class="vLandingFeedback__input-container"
          :class="{ err: errName }"
        >
          <div class="vLandingFeedback__placeholder">
            Ваше имя<i class="vLandingFeedback__star">*</i>
          </div>
          <Field
            v-slot="{ field }"
            name="name"
          >
            <input
              v-bind="field"
              id="userName"
              name="userName"
              type="text"
              :value="name"
              class="vLandingFeedback__field vLandingFeedback__name"
              @input="updateField('name', $event.target.value)"
            >
          </Field>
        </div>
        <p
          v-if="errName"
          class="vLandingFeedback__error-text"
        >
          Имя может содержать только буквы А-Я
        </p>
      </div>

      <!-- Телефон -->
      <div
        class="vLandingFeedback__input-wrap"
        :class="{ active: phone.length }"
      >
        <div
          class="vLandingFeedback__input-container"
          :class="{ err: errPhone }"
        >
          <div class="vLandingFeedback__placeholder">
            Телефон<i class="vLandingFeedback__star">*</i>
          </div>
          <Field
            v-slot="{ field }"
            name="userPhone"
          >
            <span class="vLandingFeedback__wrapper">
              <input
                v-bind="field"
                id="userPhone"
                v-maska="'+7(###) ###-##-##'"
                :value="phone"
                name="phone"
                type="text"
                class="vLandingFeedback__field vLandingFeedback__phone"
                :class="{ err: errPhone }"
                :required="true"
                @input="updateField('phone', $event.target.value)"
              >
            </span>
          </Field>
        </div>
        <p
          v-if="errPhone"
          class="vLandingFeedback__error-text"
        >
          Некорректный номер телефона!
        </p>
      </div>

      <!-- E-mail -->
      <div
        class="vLandingFeedback__input-wrap"
        :class="{ active: email.length }"
      >
        <div
          class="vLandingFeedback__input-container"
          :class="{ err: errMail }"
        >
          <div class="vLandingFeedback__placeholder">
            E-mail<i class="vLandingFeedback__star">*</i>
          </div>
          <Field
            v-slot="{ field }"
            name="email"
          >
            <input
              v-bind="field"
              id="email"
              name="email"
              type="text"
              :value="email"
              class="vLandingFeedback__field vLandingFeedback__name"
              :class="{ err: errMail }"
              @input="updateField('email', $event.target.value)"
            >
          </Field>
        </div>
        <p
          v-if="errMail"
          class="vLandingFeedback__error-text"
        >
          Не корректный E-mail
        </p>
      </div>

      <!-- City -->
      <div
        class="vLandingFeedback__input-wrap"
        :class="{ active: city.length }"
      >
        <div
          class="vLandingFeedback__input-container"
          :class="{ err: errCity }"
        >
          <div class="vLandingFeedback__placeholder">
            Город<i class="vLandingFeedback__star">*</i>
          </div>
          <Field
            v-slot="{ field }"
            name="city"
          >
            <input
              v-bind="field"
              id="city"
              name="city"
              type="text"
              :value="city"
              class="vLandingFeedback__field vLandingFeedback__name"
              :class="{ err: errCity }"
              @input="updateField('city', $event.target.value)"
            >
          </Field>
        </div>
        <p
          v-if="errCity"
          class="vLandingFeedback__error-text"
        >
          {{ cityErrorText }}
        </p>
      </div>

      <button
        aria-label="Заказать звонок"
        type="submit"
        class="vLandingFeedback__btn"
      >
        Заказать звонок
      </button>
    </Form>

    <label class="vLandingFeedback__conditions">
      <input
        v-model="checkedConditions"
        type="checkbox"
        checked="checked"
        class="vLandingFeedback__check"
      >
      <p class="vLandingFeedback__text">
        Согласен с условиями
        <a
          href="#conf"
          @click="togglePoliticModal"
        >Пользовательского соглашения и Политикой обработки персональных
          данных</a>
      </p>
    </label>
    <br>
    <p
      v-if="!checkedConditions"
      class="vLandingFeedback__error-text"
    >
      Для отправки формы согласитесь с условиями Пользовательского соглашения и
      Политикой обработки персональных данных
    </p>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import { Form, Field } from 'vee-validate'
import convertPhoneFormat from '@/utils/convertPhoneFormat'
export default {
  name: 'VLandingFeedback',
  components: {
    Form,
    Field,
  },
  emits: ['togglePoliticModal'],
  data() {
    return {
      name: '',
      phone: '',
      email: '',
      city: '',
      cityErrorText: '',
      test: null,
      err: false,
      errPhone: false,
      errName: false,
      errMail: false,
      errCity: false,
      checkedConditions: true,
      utm: {},
    }
  },
  mounted() {
    const fullUrl = window.location.search.substr(1)
    if (fullUrl) {
      const arrayUrl = fullUrl.split('&')
      const utmArra = arrayUrl.filter((i) => i.includes('utm'))
      utmArra.forEach((i) => {
        const arr = i.split('=')
        this.utm[arr[0]] = arr[1]
      })
    }
  },
  methods: {
    ...mapActions('feedback', ['FEEDBACK']),
    ...mapActions('modalPolitic', ['CHANGE_MODAL_POLITIC']),
    updateField(el, val) {
      this[el] = val
    },
    async checkForm() {
      this.err = false
      this.errName = false
      this.errPhone = false
      this.errMail = false
      this.errCity = false

      const regexp = /^[а-яА-ЯёЁ\s]+$/
      const res = regexp.test(this.name)
      const resCity = regexp.test(this.city)

      const regexpMail = /^\w.+@[a-zA-Z_].+?\.[a-zA-Z]{2,9}$/
      const resMail = regexpMail.test(this.email)

      if (!res) {
        this.errName = true
      }

      if (!resMail) {
        this.errMail = true
      }

      if (this.phone.length !== 17) {
        this.errPhone = true
      }

      if (!resCity) {
        this.cityErrorText = 'Название города может содержать только буквы А-Я'
        this.errCity = true
      }

      if (this.city.length < 2) {
        this.cityErrorText = 'Введите не менее 2 символов'
        this.errCity = true
      }

      if (this.checkedConditions) {
        if (
          this.phone.length == 17 &&
          !this.errName &&
          !this.errMail &&
          !this.errCity
        ) {
          ym(85537528, 'reachGoal', 'zvonok')

          let req = {
            name: this.name,
            phone: convertPhoneFormat(this.phone),
            email: this.email,
            city: this.city,
          }

          if (Object.keys(this.utm)) {
            req = { ...req, ...this.utm }
          }

          this.FEEDBACK(req)
          this.updateField('name', '')
          this.updateField('phone', '')
          this.updateField('email', '')
          this.updateField('city', '')
          this.utm = {}
        } else {
          this.err = true
        }
      }
    },
    togglePoliticModal() {
      this.CHANGE_MODAL_POLITIC()
    },
  },
}
</script>

<style lang="scss">
.vLandingFeedback {
  padding: 92px 38px;
  background: linear-gradient(80.89deg, #0c0c10 0%, #14141b 99.55%);
  border: 2px solid #2c3038;
  border-radius: 30px;
  @media (max-width: 769px) {
    padding: 40px 16px;
  }
  &__title {
    margin-bottom: 56px !important;
    font-size: 36px;
    line-height: 44px;
    @media (max-width: 769px) {
      margin-bottom: 32px !important;
      font-size: 22px;
      line-height: 30px;
    }
  }
  &__form {
    display: flex;
    justify-content: space-between;
    margin-bottom: 18px;
    font-family: 'PTRootUI';
    align-items: flex-start;
    @media (max-width: 769px) {
      flex-direction: column;
    }
  }
  &__field {
    width: 100%;
    padding: 16px 12px;
    font-family: 'Stolzl';
    font-weight: 500;
    font-size: 16px;
    line-height: 28px;
    background: transparent;
    border: none;
    position: relative;
    z-index: 2;
    @media (max-width: 769px) {
      width: 100%;
      font-size: 16px;
      line-height: 20px;
    }
    &::placeholder {
      font-family: 'PTRootUI';
      font-weight: 500;
      font-size: 18px;
      line-height: 28px;
      color: #5a5a68;
      @media (max-width: 769px) {
        text-indent: 0;
      }
    }
  }
  &__name::placeholder {
    @media (max-width: 769px) {
      background: none;
    }
  }
  &__phone::placeholder {
    @media (max-width: 769px) {
      background: none;
    }
  }
  &__btn {
    width: 31%;
    font-family: 'Stolzl';
    font-weight: 500;
    font-size: 20px;
    line-height: 28px;
    color: #fbfbfc;
    background: #c01718;
    border-radius: 8px;
    border: none;
    cursor: pointer;
    height: 62px;
    &:hover {
      background-color: #e01b1c;
    }
    @media (max-width: 769px) {
      width: 100%;
      padding: 16px 0;
      font-size: 18px;
      line-height: 28px;
      height: auto;
    }
  }
  &__conditions {
    margin-top: 18px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  &__star {
    color: #ec4e4e;
  }
  &__conditions,
  &__conditions a {
    font-family: 'PTRootUI';
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #e4e4eb;
  }
  &__check {
    position: absolute;
    z-index: -1;
    opacity: 0;
    &:checked + .vLandingFeedback__text::before {
      background-image: url(~@/assets/images/input_check.svg);
    }
  }
  &__text {
    &::before {
      content: '';
      display: inline-block;
      width: 14px;
      height: 14px;
      flex-shrink: 0;
      flex-grow: 0;
      border: 1.5px solid #dcdee3;
      border-radius: 0.4em;
      margin-right: 17px;
      background-repeat: no-repeat;
      background-position: center center;
      background-size: 12px;
    }
  }
  &__invalid-icon {
    position: absolute;
    top: 50%;
    right: 19px;
    transform: translateY(-50%);
    cursor: pointer;
  }
  &__wrapper {
    position: relative;
    width: 31%;
    @media (max-width: 769px) {
      width: 100%;
      margin-bottom: 16px;
    }
    input {
      width: 100%;
      @media (max-width: 769px) {
        margin-bottom: 0;
      }
    }
  }
  &__error-text {
    margin-top: 18px !important;
    font-weight: 500;
    font-size: 16px;
    line-height: 18px;
    text-align: center;
    color: #ec4e4e;
    font-family: 'PTRootUI';
  }
  &__placeholder {
    position: absolute;
    color: #80869a;
    font-weight: 500;
    font-size: 16px;
    left: 12px;
    top: 50%;
    transform: translateY(-50%);
    z-index: 1;
  }
  &__input-container {
    background: #fbfbfc;
    border: 1px solid #e6e7eb;
    position: relative;
    border-radius: 8px;
    &.err {
      background: #f8e0e0;
      border: 1px solid #ec4e4e;
    }
  }
  &__input-wrap {
    width: 25%;
    margin-right: 20px;
    position: relative;
    overflow: hidden;
    @media (max-width: 769px) {
      margin-right: 0;
      width: 100%;
      margin-bottom: 16px;
    }
    input {
      width: 100%;
    }
  }
}

.active .vLandingFeedback__placeholder {
  display: none;
}
</style>
