<template>
  <vLandingMain @toggleRegistrationModal="toggleRegistrationModal" />
  <vLandingDemoMobile />
  <vLandingHowWork />
  <vLandingIntegration />
  <vLandingReasons />
  <vLandingNotall />
  <vLandingImplement @toggleRegistrationModal="toggleRegistrationModal" />
  <vLandingPosibilities />
  <vLandingQuestions />
  <VLandingRegistrationModal
    :registration-modal="registrationModal"
    @toggleRegistrationModal="toggleRegistrationModal"
  />

  <!-- Modal Feedback -->
  <vModalSuccess
    v-if="GET_FEEDBACK_SUCCESS"
    :close-bth="true"
    @Modal="closeModalSuccess"
  >
    <template #img>
      <img
        src="~@/assets/images/reg_complete_img.png"
        alt=""
      >
    </template>
    <template #title>
      Спасибо, заявка успешно отправлена
    </template>
    <template #description>
      Мы свяжемся с вами в ближайшее время
    </template>
  </vModalSuccess>

  <!-- Modal Registration -->
  <vModalSuccess
    v-if="GET_REGISTRATION_SUCCESS"
    :close-bth="false"
    @closeModal="closeModalRegistrationSuccess"
  >
    <template #img>
      <img
        src="~@/assets/images/reg_complete_img.png"
        alt=""
      >
    </template>
    <template #title>
      Вы успешно зарегистрированы!
    </template>
    <template #description>
      Через несколько секунд вы будете перенаправлены в Личный кабинет
    </template>
  </vModalSuccess>

  <vModalSocials
    v-if="modalSocial"
    @toggleModal="toggleModal"
  />
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import vLandingMain from '@/components/landing/v-landing-main'
import vLandingHowWork from '@/components/landing/v-landing-howwork'
import vLandingDemoMobile from '@/components/landing/v-landing-demo-mobile'
import vLandingIntegration from '@/components/landing/v-landing-integration'
import vLandingReasons from '@/components/landing/v-landing-reasons'
import vLandingNotall from '@/components/landing/v-landing-notall'
import vLandingImplement from '@/components/landing/v-landing-implement'
import vLandingPosibilities from '@/components/landing/v-landing-posibilities'
import vLandingQuestions from '@/components/landing/v-landing-questions'
import VLandingRegistrationModal from '@/components/landing/v-landing-registration-modal'
import vModalSuccess from '@/components/modals/v-modal-success'
import vModalSocials from '@/components/modals/v-modal-socials'
export default {
  name: 'Landing',
  components: {
    vLandingMain,
    vLandingDemoMobile,
    VLandingRegistrationModal,
    vLandingHowWork,
    vLandingIntegration,
    vLandingReasons,
    vLandingNotall,
    vLandingImplement,
    vLandingPosibilities,
    vLandingQuestions,
    vModalSuccess,
    vModalSocials,
  },
  data() {
    return {
      registrationModal: false,
      modalSocial: false,
    }
  },
  computed: {
    ...mapGetters('feedback', ['GET_FEEDBACK_SUCCESS']),
    ...mapGetters('user', ['GET_REGISTRATION_SUCCESS']),
  },
  mounted() {
    setTimeout(async () => {
      const status = await localStorage.getItem('socials')
      if (status === null) {
        this.toggleModal()
        localStorage.setItem('socials', '1')
      }
    }, 25000)
  },
  methods: {
    ...mapActions('feedback', ['CLOSE_ACCESS']),
    ...mapActions('user', ['CLOSE_REGISTRATION_ACCESS']),
    closeModalSuccess() {
      this.CLOSE_ACCESS()
    },
    closeModalRegistrationSuccess() {
      this.CLOSE_REGISTRATION_ACCESS()
    },
    toggleRegistrationModal() {
      this.registrationModal = !this.registrationModal
    },
    toggleModal() {
      this.modalSocial = !this.modalSocial
    },
  },
}
</script>
