<template>
  <section class="vLandingImplement">
    <div class="vLandingImplement__container landing-container">
      <h2 class="vLandingImplement__title landing-section-title">
        Пора внедрять!
      </h2>
      <p class="vLandingImplement__text">
        Tapper -
        <span class="vLandingImplement__text--bold">бесплатный</span> сервис.
      </p>
      <p class="vLandingImplement__text">
        Повысьте уровень вашего заведения без лишних расходов!
      </p>
      <a
        v-scroll-to="'#vLandingQuestions__callback'"
        href="#"
        class="vLandingImplement__connect-link"
      >
        Оставить заявку
        <svg
          width="16"
          height="14"
          viewBox="0 0 16 14"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M1 7.99997H12.17L8.29 11.88C7.9 12.27 7.9 12.91 8.29 13.3C8.68 13.69 9.31 13.69 9.7 13.3L15.29 7.70997C15.68 7.31997 15.68 6.68997 15.29 6.29997L9.71 0.699971C9.32 0.309971 8.69 0.309971 8.3 0.699971C7.91 1.08997 7.91 1.71997 8.3 2.10997L12.17 5.99997H1C0.45 5.99997 0 6.44997 0 6.99997C0 7.54997 0.45 7.99997 1 7.99997Z"
            fill="#FBFBFC"
          />
        </svg>
      </a>
    </div>
  </section>
</template>

<script>
export default {
  name: 'VLandingImplement',
  emits: ['toggleRegistrationModal'],
  methods: {
    toggleRegistrationModal() {
      this.$emit('toggleRegistrationModal')
    },
  },
}
</script>

<style lang="scss">
.vLandingImplement {
  position: relative;
  padding: 100px 0 124px;
  text-align: center;
  &::before {
    content: '';
    background: linear-gradient(
        78.24deg,
        #14141b 11.16%,
        rgba(20, 20, 27, 0) 72.52%
      ),
      url(~@/assets/images/bg_implement.png) center/cover no-repeat;
    opacity: 0.7;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    position: absolute;
    z-index: 0;
  }
  &__container {
    position: relative;
    z-index: 1;
  }
  &__title {
    margin-bottom: 56px;
  }
  &__text {
    font-family: 'PTRootUI';
    font-weight: 500;
    font-size: 22px;
    line-height: 30px;
    color: #e4e4eb;
    @media (max-width: 769px) {
      font-size: 18px;
      line-height: 24px;
    }
    &:last-of-type {
      margin-bottom: 40px;
    }
    &--bold {
      font-weight: 700;
    }
  }
  &__connect-link {
    font-family: 'PTRootUI';
    display: inline-block;
    padding: 16px 28px;
    font-weight: 500;
    font-size: 20px;
    line-height: 28px;
    color: #fbfbfc;
    background-color: #c01718;
    border-radius: 8px;
    text-decoration: none;
    cursor: pointer;
    &:hover {
      background-color: #e01b1c;
    }
    @media (max-width: 991px) {
      padding: 16px 10px;
      font-size: 16px;
    }
    @media (max-width: 769px) {
      width: 100%;
      margin-bottom: 20px;
      text-align: center;
      font-size: 18px;
      line-height: 22px;
    }
  }
}
</style>
