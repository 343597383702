<template>
  <section class="vLandingNotall">
    <div class="landing-container">
      <svg
        width="585"
        height="348"
        viewBox="0 0 585 348"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        class="top-bg"
      >
        <g opacity="0.07">
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M0 348L-1.52116e-05 0L147.5 -6.44743e-06C228.41 -9.98412e-06 294 77.9021 294 174L294 348L0 348Z"
            fill="#B1B6C5"
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M72 296.175C111.765 296.175 144 262.334 144 220.588C144 178.842 111.765 145 72 145C32.2355 145 1.47927e-06 178.842 3.30404e-06 220.588C5.12882e-06 262.334 32.2355 296.175 72 296.175Z"
            fill="#31313C"
          />
          <rect
            y="296.176"
            width="79"
            height="78"
            transform="rotate(-90 0 296.176)"
            fill="#31313C"
          />
        </g>
      </svg>
      <svg
        width="306"
        height="294"
        viewBox="0 0 306 294"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        class="bottom-bg"
      >
        <g opacity="0.07">
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M305.958 294L0.478821 294L0.478834 146.5C0.478841 65.59 68.8623 -2.07275e-05 153.218 -1.33529e-05L305.958 0L305.958 294Z"
            fill="#B1B6C5"
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M304.175 219C304.175 179.236 270.334 147 228.588 147C186.842 147 153 179.235 153 219C153 258.765 186.842 291 228.588 291C270.334 291 304.175 258.765 304.175 219Z"
            fill="#31313C"
          />
          <rect
            x="304.176"
            y="291"
            width="79"
            height="78"
            transform="rotate(-180 304.176 291)"
            fill="#31313C"
          />
        </g>
      </svg>
      <h2 class="vLandingNotall__title landing-section-title">
        И это ещё не всё...
      </h2>
      <div class="vLandingNotall__carousel">
        <swiper
          :slides-per-view="1"
          :loop="true"
          :speed="1300"
          :space-between="30"
          :pagination="true"
          :auto-height="true"
          :breakpoints="{
            991: {
              slidesPerView: 4,
            },
            767: {
              slidesPerView: 2,
            },
          }"
        >
          <SwiperSlide class="vLandingNotall__carousel-slide">
            <svg
              class="vLandingNotall__icon"
              width="80"
              height="80"
              viewBox="0 0 80 80"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M40 80C62.0914 80 80 62.0914 80 40C80 17.9086 62.0914 0 40 0C17.9086 0 0 17.9086 0 40C0 62.0914 17.9086 80 40 80Z"
                fill="#31313C"
              />
              <path
                d="M49.6669 49.6667H46.3335M30.3269 30.3267H36.6669V36.6667H30.3269V30.3267ZM30.2002 43.5334H36.6669V50H30.2002V43.5334ZM43.3335 46.6667V43.3334V46.6667Z"
                stroke="#FBFBFC"
                stroke-width="2.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M49.7996 36.4661H43.3329V29.9995H49.7996V36.4661Z"
                stroke="#FBFBFC"
                stroke-width="2.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M55.8334 33.2967V27.5C55.8334 25.6583 54.3417 24.1667 52.5001 24.1667H46.7034M47.1667 43.3333H49.6667M33.2967 24.1667H27.5001C25.6584 24.1667 24.1667 25.6583 24.1667 27.5V33.2967M24.1667 46.7033V52.5C24.1667 54.3417 25.6584 55.8333 27.5001 55.8333H33.2967M46.7034 55.8333H52.5001C54.3417 55.8333 55.8334 54.3417 55.8334 52.5V46.7033"
                stroke="#FBFBFC"
                stroke-width="2.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>

            <h3 class="vLandingNotall__carousel-title">
              Никаких приложений и регистраций
            </h3>

            <p class="vLandingNotall__text">
              Все работает в браузере после сканирования <br>
              qr-кода у стола
            </p>
          </SwiperSlide>
          <SwiperSlide class="vLandingNotall__carousel-slide">
            <svg
              width="80"
              height="80"
              viewBox="0 0 80 80"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              class="vLandingNotall__icon"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M40 80C62.0914 80 80 62.0914 80 40C80 17.9086 62.0914 0 40 0C17.9086 0 0 17.9086 0 40C0 62.0914 17.9086 80 40 80Z"
                fill="#31313C"
              />
              <path
                d="M47.6852 41.325C46.3319 41.325 45.0502 41.0267 43.8986 40.4917L38.3652 41.6667L39.5236 36.1233C38.9852 34.97 38.6852 33.6817 38.6852 32.325M49.2269 29.5833C50.6369 29.5833 51.5836 30.825 51.5836 31.9817C51.5836 34.3283 47.6552 36.25 47.5836 36.25C47.5119 36.25 43.5836 34.3283 43.5836 31.9817C43.5836 30.825 44.5302 29.5833 45.9402 29.5833C46.7469 29.5833 47.2769 29.96 47.5836 30.2967C47.8902 29.96 48.4202 29.5833 49.2269 29.5833V29.5833Z"
                stroke="#FBFBFC"
                stroke-width="2.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M47.675 41.315C52.64 41.315 56.6667 37.29 56.6667 32.3233C56.6667 27.3567 52.6417 23.3333 47.675 23.3333C42.7083 23.3333 38.6833 27.3583 38.6833 32.325"
                stroke="#FBFBFC"
                stroke-width="2.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M45.0001 40.915V52.9167C45.0001 54.9884 43.3584 56.6667 41.3334 56.6667H30.3334C28.3084 56.6667 26.6667 54.9884 26.6667 52.9167V30.4167C26.6667 28.345 28.3084 26.6667 30.3334 26.6667H40.6884M33.8001 51.6667H37.8667H33.8001Z"
                stroke="#FBFBFC"
                stroke-width="2.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>

            <h3 class="vLandingNotall__carousel-title">
              Получение обратной связи
            </h3>

            <p class="vLandingNotall__text">
              Гости охотнее оставят отзыв в сервисе, чем скажут официанту
            </p>
          </SwiperSlide>
          <SwiperSlide class="vLandingNotall__carousel-slide">
            <svg
              width="80"
              height="80"
              viewBox="0 0 80 80"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              class="vLandingNotall__icon"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M40 80C62.0914 80 80 62.0914 80 40C80 17.9086 62.0914 0 40 0C17.9086 0 0 17.9086 0 40C0 62.0914 17.9086 80 40 80Z"
                fill="#31313C"
              />
              <path
                d="M39.9999 43.3334V36.6667M26.6665 38.7384V31.5201V31.52C26.6605 30.6627 27.3127 29.9438 28.1666 29.8667L28.1666 29.8667C32.1418 29.5554 35.9147 27.9863 38.9382 25.3867V25.3867C39.5522 24.8715 40.4475 24.8715 41.0616 25.3867V25.3867C44.0847 27.987 47.8578 29.5562 51.8332 29.8667V29.8667C52.6874 29.9447 53.3394 30.664 53.3332 31.5217V38.7401V38.7401C53.1477 46.2635 48.1032 52.797 40.8715 54.8801V54.8801C40.302 55.04 39.6994 55.04 39.1299 54.8801V54.8801C31.897 52.7974 26.8515 46.2629 26.6666 38.7384L26.6665 38.7384ZM36.6665 40.0001H43.3332H36.6665Z"
                stroke="#FBFBFC"
                stroke-width="2.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>

            <h3 class="vLandingNotall__carousel-title">
              Повышение безопасности
            </h3>

            <p class="vLandingNotall__text">
              Снижение количества контактов между гостями и персоналом
            </p>
          </SwiperSlide>
          <SwiperSlide class="vLandingNotall__carousel-slide">
            <svg
              width="80"
              height="80"
              viewBox="0 0 80 80"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              class="vLandingNotall__icon"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M40 80C62.0914 80 80 62.0914 80 40C80 17.9086 62.0914 0 40 0C17.9086 0 0 17.9086 0 40C0 62.0914 17.9086 80 40 80Z"
                fill="#31313C"
              />
              <path
                d="M39.6581 48.1767L36.2164 51.5251C35.6198 52.1051 34.6214 51.8484 34.3798 51.0517L32.0864 43.5017"
                stroke="#FBFBFC"
                stroke-width="2.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M43.5066 38.2117C43.5066 38.2117 39.1916 42.105 36.9783 44.1034C36.3166 44.7 36.3716 45.75 37.0883 46.2784L46.0516 52.9C46.9333 53.5517 48.1933 53.0734 48.4216 52L52.8233 31.255C53.0366 30.2517 52.0516 29.4134 51.095 29.7817L25.2366 39.755C24.475 40.0484 24.5116 41.1367 25.29 41.38L32.085 43.5"
                stroke="#FBFBFC"
                stroke-width="2.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
            <h3 class="vLandingNotall__carousel-title">
              Отчёты руководству
            </h3>
            <p class="vLandingNotall__text">
              Каждый вечер на мессенджер приходит отчет со всеми данными
            </p>
          </SwiperSlide>
        </swiper>
      </div>
    </div>
  </section>
</template>

<script>
import SwiperCore, { Pagination } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/vue'
import 'swiper/swiper.scss'

SwiperCore.use([Pagination])

export default {
  name: 'VLandingNotall',
  components: {
    Swiper,
    SwiperSlide,
  },
}
</script>

<style lang="scss">
.vLandingNotall {
  position: relative;
  padding: 100px 0;
  background: linear-gradient(88.41deg, #14141b 44.06%, #0c0c10 81.96%);
  overflow-x: hidden;
  .top-bg {
    position: absolute;
    top: 0;
    left: 0;
  }
  .bottom-bg {
    position: absolute;
    bottom: 0;
    right: 0;
  }
  &__title {
    margin-bottom: 92px;
    text-align: center;
    @media (max-width: 991px) {
      margin-bottom: 72px;
    }
    @media (max-width: 769px) {
      margin-bottom: 40px;
    }
  }
  &__carousel-slide {
    display: flex !important;
    flex-direction: column;
    align-items: center;
    padding: 32px 22px;
    margin-right: 30px;
    font-family: 'PTRootUI';
    font-weight: 500;
    text-align: center;
    background-color: #fbfbfc;
    border: 2px solid #dfe4ea;
    border-radius: 15px;
    min-height: 376px;
    // height: 100% !important;

    @media (max-width: 576px) {
      min-height: 324px;
    }
  }
  &__icon {
    margin-bottom: 14px;
  }
  &__carousel-title {
    flex-grow: 1;
    font-size: 22px;
    line-height: 30px;
    color: #14141b;

    @media (max-width: 767px) {
      max-width: 220px;
    }
  }
  &__text {
    padding-top: 16px !important;
    font-size: 18px;
    line-height: 26px;
    color: #5a5a68;
    border-top: 2px solid #edeff4;
  }

  .swiper-container {
    @media (max-width: 576px) {
      overflow: visible;
    }
  }

  .swiper-pagination {
    position: absolute;
    left: 0;
    bottom: -60px;
    display: none;
    width: 100%;
    padding: 0;
    margin: 0;
    text-align: center;

    @media (max-width: 576px) {
      display: block;
    }
  }

  .swiper-pagination-bullet {
    display: inline-block;
    width: 10px;
    height: 10px;
    margin: 0 5px;
    background: #31313c !important;
    border-radius: 50%;
  }
  .swiper-pagination-bullet-active {
    background: white !important;
  }
}
</style>
