<template>
  <div
    class="vLandingRegistrationModal"
    :class="{ active: registrationModal }"
  >
    <div
      class="vLandingRegistrationModal__overlay"
      @click="toggleRegistrationModal"
    />
    <div class="vLandingRegistrationModal__wrapper">
      <div class="vLandingRegistrationModal__content">
        <div class="vLandingRegistrationModal__header">
          <div
            class="vLandingRegistrationModal__close"
            @click="toggleRegistrationModal"
          />
          <div class="vLandingRegistrationModal__title">
            Зарегистрироваться в Tapper
          </div>
          <div class="vLandingRegistrationModal__body">
            <div class="vLandingRegistrationModal__text">
              <div class="vLandingRegistrationModal__hasacc">
                Уже есть аккаунт?
              </div>
              <router-link
                v-if="!GET_AUTHENTIFICATED"
                to="/users/"
                class="vLandingRegistrationModal__registration-link"
              >
                Войти
                <svg
                  width="16"
                  height="14"
                  viewBox="0 0 16 14"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M1 7.99997H12.17L8.29 11.88C7.9 12.27 7.9 12.91 8.29 13.3C8.68 13.69 9.31 13.69 9.7 13.3L15.29 7.70997C15.68 7.31997 15.68 6.68997 15.29 6.29997L9.71 0.699971C9.32 0.309971 8.69 0.309971 8.3 0.699971C7.91 1.08997 7.91 1.71997 8.3 2.10997L12.17 5.99997H1C0.45 5.99997 0 6.44997 0 6.99997C0 7.54997 0.45 7.99997 1 7.99997Z"
                    fill="#FBFBFC"
                  />
                </svg>
              </router-link>
              <router-link
                v-else
                to="/profile/"
                class="vLandingRegistrationModal__registration-link"
              >
                Войти
                <svg
                  width="16"
                  height="14"
                  viewBox="0 0 16 14"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M1 7.99997H12.17L8.29 11.88C7.9 12.27 7.9 12.91 8.29 13.3C8.68 13.69 9.31 13.69 9.7 13.3L15.29 7.70997C15.68 7.31997 15.68 6.68997 15.29 6.29997L9.71 0.699971C9.32 0.309971 8.69 0.309971 8.3 0.699971C7.91 1.08997 7.91 1.71997 8.3 2.10997L12.17 5.99997H1C0.45 5.99997 0 6.44997 0 6.99997C0 7.54997 0.45 7.99997 1 7.99997Z"
                    fill="#FBFBFC"
                  />
                </svg>
              </router-link>
            </div>
          </div>
        </div>
        <vLandingRegistrationForm
          @toggleRegistrationModal="toggleRegistrationModal"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import vLandingRegistrationForm from '@/components/landing/v-landing-registration-form'
export default {
  name: 'VLandingRegistrationModal',
  components: {
    vLandingRegistrationForm,
  },
  props: {
    registrationModal: {
      type: Boolean,
      require: true,
      default: false,
    },
  },
  emits: ['toggleRegistrationModal'],
  computed: {
    ...mapGetters('user', ['GET_AUTHENTIFICATED']),
  },
  methods: {
    toggleRegistrationModal() {
      this.$emit('toggleRegistrationModal')
    },
  },
}
</script>

<style lang="scss">
.vLandingRegistrationModal {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: -1;
  opacity: 0;
  transition: all 0.3s;
  &.active {
    z-index: 11;
    opacity: 1;
  }
  &__overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #212129;
    opacity: 0.95;
    z-index: 1;
  }
  &__wrapper {
    padding: 35px 0;
    overflow: auto;
    max-height: 100vh;
    width: 100%;
    max-width: 600px;
    &::-webkit-scrollbar {
      width: 0;
    }
    @media (max-width: 769px) {
      max-width: 87.5%;
    }
  }
  &__content {
    position: relative;
    z-index: 2;
    padding: 24px;
    font-family: 'PTRootUI';
    font-weight: 500;
    background: linear-gradient(94.19deg, #14141b 48.83%, #0c0c10 78.63%);
    border: 2px solid #2c3038;
    border-radius: 16px;
    z-index: 4;
    overflow: hidden;
    @media (max-width: 576px) {
      padding: 72px 16px 24px;
    }
  }
  &__header {
    width: 100%;
    max-width: 301px;
    margin: 0 auto;
  }
  &__title {
    margin-bottom: 24px !important;
    font-family: 'Stolzl';
    font-weight: 400;
    font-size: 28px;
    line-height: 38px;
    text-align: center;
    letter-spacing: -0.7px;
    color: #fbfbfc;
  }
  &__close {
    position: absolute;
    top: 24px;
    right: 16px;
    min-width: 24px;
    width: 24px;
    max-width: 24px;
    min-height: 24px;
    height: 24px;
    max-height: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #fbfbfc;
    cursor: pointer;
    border-radius: 50%;
    border: none;
    &::before {
      content: '';
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%) rotate(45deg);
      height: 2px;
      width: 10px;
      background: #c01718;
    }
    &::after {
      content: '';
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%) rotate(-45deg);
      height: 2px;
      width: 10px;
      background: #c01718;
    }
    &:hover {
      background: #c01718;
      &::after,
      &::before {
        background: #fff;
      }
    }
  }
  &__text {
    margin-bottom: 24px;
  }
  &__hasacc {
    display: inline-block;
    margin-right: 8px !important;
    font-size: 22px;
    line-height: 30px;
    color: #e4e4eb;
    opacity: 0.7;
  }
  &__registration-link {
    font-size: 22px;
    line-height: 30px;
    color: #fbfbfc;
    text-decoration: none;
    cursor: pointer;
  }

  &__invalid-icon {
    position: absolute;
    top: 50%;
    right: 19px;
    transform: translateY(-50%);
    cursor: pointer;
  }
  &__err-text {
    padding-left: 12px;
    margin-top: 4px;
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    color: #ec4e4e;
  }
  &__eye-icon {
    position: absolute;
    top: 50%;
    right: 19px;
    transform: translateY(-50%);
    cursor: pointer;
  }
}
</style>
