<template>
  <section
    id="posibilities"
    class="vLandingPosibilities"
  >
    <div class="landing-container">
      <h2 class="vLandingPosibilities__title landing-section-title">
        Возможности для клиентов Tapper
      </h2>
      <div class="vLandingPosibilities__content">
        <div class="vLandingPosibilities__text">
          <p class="vLandingPosibilities__content-text">
            Мы открыты и можем индивидуально реализовать любую идею по
            функционалу сервиса.
          </p>
          <p class="vLandingPosibilities__content-text">
            По запросу доработаем интерфейс и функционал с учетом особенностей
            вашего заведения.
          </p>
        </div>
        <img
          src="~@/assets/images/posibilities_img.png"
          class="vLandingPosibilities__img"
        >
      </div>
      <svg
        width="585"
        height="612"
        viewBox="0 0 585 612"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        class="vLandingPosibilities__top-bg"
      >
        <g opacity="0.07">
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M585 0.0427246L585 305.521L437.5 305.521C356.59 305.521 291 237.138 291 152.782L291 0.0427118L585 0.0427246Z"
            fill="#B1B6C5"
          />
          <rect
            width="152"
            height="155.346"
            transform="matrix(1 4.37114e-08 4.37114e-08 -1 0 155.389)"
            fill="#2C3038"
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M513 -4.89236e-05C473.236 -5.06617e-05 441 33.8417 441 75.5877C441 117.334 473.235 151.175 513 151.175C552.765 151.175 585 117.334 585 75.5877C585 33.8417 552.765 -4.71854e-05 513 -4.89236e-05Z"
            fill="#31313C"
          />
          <rect
            x="585"
            y="-0.000488281"
            width="79"
            height="78"
            transform="rotate(90 585 -0.000488281)"
            fill="#31313C"
          />
        </g>
      </svg>
      <svg
        width="585"
        height="612"
        viewBox="0 0 585 612"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        class="vLandingPosibilities__bottom-bg"
      >
        <g opacity="0.07">
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M0 611.957L-1.33529e-05 306.479L147.5 306.479C228.41 306.479 294 374.862 294 459.218L294 611.957L0 611.957Z"
            fill="#B1B6C5"
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M439.5 611.957C359.143 611.957 294 543.807 294 459.739C294 375.672 359.143 307.521 439.5 307.521C519.857 307.521 585 375.672 585 459.739C585 543.807 519.857 611.957 439.5 611.957Z"
            fill="#2C3038"
          />
          <rect
            width="152"
            height="155.346"
            transform="matrix(-1 4.37114e-08 4.37114e-08 1 585 456.611)"
            fill="#2C3038"
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M72 612C111.765 612 144 578.158 144 536.412C144 494.666 111.765 460.825 72 460.825C32.2355 460.825 1.47927e-06 494.666 3.30404e-06 536.412C5.12882e-06 578.158 32.2355 612 72 612Z"
            fill="#31313C"
          />
          <rect
            y="612"
            width="79"
            height="78"
            transform="rotate(-90 0 612)"
            fill="#31313C"
          />
        </g>
      </svg>
    </div>
  </section>
</template>

<script>
export default {
  name: 'VLandingPosibilities',
}
</script>

<style lang="scss">
.vLandingPosibilities {
  position: relative;
  padding: 100px 0 100px;
  background: linear-gradient(94.19deg, #14141b 48.83%, #0c0c10 78.63%);
  z-index: 5;
  @media (max-width: 769px) {
    padding: 60px 0 56px;
  }
  &__title {
    margin-bottom: 92px;
    @media (max-width: 769px) {
      margin-bottom: 40px;
    }
  }
  &__content {
    display: flex;
    justify-content: space-evenly;
    @media (max-width: 769px) {
      flex-direction: column;
    }
  }
  &__text {
    max-width: 500px;
    padding-top: 36px;
    @media (max-width: 991px) {
      margin-right: 20px;
    }
    @media (max-width: 769px) {
      order: 2;
      margin-right: 0;
    }
  }
  &__content-text {
    font-family: 'PTRootUI';
    font-weight: 500;
    font-size: 36px;
    line-height: 44px;
    color: #e4e4eb;
    &:first-of-type {
      margin-bottom: 50px;
    }
    @media (max-width: 991px) {
      font-size: 28px;
      line-height: 40px;
    }
    @media (max-width: 769px) {
      font-size: 22px;
      line-height: 30px;
    }
  }
  &__img {
    @media (max-width: 991px) {
      width: 50%;
    }
    @media (max-width: 769px) {
      order: 1;
      width: 85%;
      margin: 0 auto;
    }
  }
  &__top-bg {
    position: absolute;
    top: 0;
    right: 0;
    @media (max-width: 769px) {
      display: none;
    }
  }
  &__bottom-bg {
    position: absolute;
    bottom: 0;
    left: 0;
    @media (max-width: 769px) {
      display: none;
    }
  }
}
</style>
