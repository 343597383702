<template>
  <section
    id="integrations"
    class="vLandingIntegration"
  >
    <div class="vLandingIntegration__container landing-container">
      <h2 class="vLandingIntegration__title landing-section-title">
        Официальные интеграции
      </h2>
      <div class="vLandingIntegration__logos">
        <a
          href="https://rkeeper.ru/"
          target="_blank"
        >
          <img
            class="rkeeper"
            src="~@/assets/images/rkeeper.svg"
            alt="rkeeper"
          >
        </a>
        <a
          href="https://iiko.ru/"
          target="_blank"
        >
          <img
            src="~@/assets/images/iiko.svg"
            alt="iiko"
          >
        </a>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'VLandingIntegration',
}
</script>

<style lang="scss">
.vLandingIntegration {
  position: relative;
  padding: 202px 0 142px;
  padding-bottom: 137px;
  text-align: center;
  &::before {
    content: '';
    background: linear-gradient(
        87.3deg,
        #14141b 21.07%,
        rgba(20, 20, 27, 0.43) 93.52%
      ),
      url('~@/assets/images/bg_integration.png') center/cover no-repeat;
    opacity: 0.7;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    position: absolute;
    z-index: 0;
  }
  @media (max-width: 769px) {
    padding: 100px 0;
  }
  &__container {
    padding: 0 48px;
    position: relative;
    z-index: 1;
  }
  &__title {
    margin-bottom: 33px;
    @media (max-width: 769px) {
      margin-bottom: 27px;
    }
  }
  .rkeeper {
    margin-right: 16px;
    @media (max-width: 447px) {
      margin-right: 0;
      margin-bottom: 15px;
    }
  }
  &__logos {
    @media (max-width: 447px) {
      display: flex;
      flex-direction: column;
    }
    img {
      @media (max-width: 447px) {
        max-height: 60px;
      }
    }
  }
}
</style>
