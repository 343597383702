<template>
  <Form
    class="vLandingRegistrationForm"
    @submit="checkForm"
  >
    <!-- Имя -->
    <vLandingInput
      id="name"
      v-model="name"
      :value="name"
      placeholder="Ваше имя"
      :required="true"
      rule="required|rus"
    />

    <!-- Телефон -->
    <vLandingInput
      id="phone"
      v-model="phone"
      :value="phone"
      placeholder="Телефон"
      :required="true"
      rule="phone"
      :error="err && !phone.length"
    />

    <!-- E-mail -->
    <vLandingInput
      id="email"
      v-model="email"
      :value="email"
      placeholder="E-mail"
      :required="true"
      rule="mail|max:254"
      :error="USER_ERROR"
    />

    <!-- Пароль -->
    <vLandingInput
      id="password"
      v-model.trim="password"
      type="password"
      :value="password"
      placeholder="Пароль"
      :required="true"
      rule="min:6"
    />

    <!-- Подтверждение пароля -->
    <vLandingInput
      id="confirmation"
      v-model.trim="confirmPassword"
      type="password"
      :value="confirmPassword"
      placeholder="Подтверждение пароля"
      :required="true"
      rules="confirmed:@password"
    />

    <!-- Название заведения -->
    <vLandingInput
      id="name_shop"
      v-model="name_shop"
      :value="name_shop"
      placeholder="Название заведения"
      :required="true"
      rules="required"
      :error="SHOP_ERROR"
    />

    <!-- Recaptcha -->
    <vue-recaptcha
      v-show="showRecaptcha"
      ref="vueRecaptcha"
      site-key="6Ldqy-cUAAAAAPUTV7PRmVrVjhcW1g2VmHUhguaF"
      size="normal"
      theme="light"
      hl="ru"
      @verify="recaptchaVerified"
      @expire="recaptchaExpired"
      @fail="recaptchaFailed"
    />
    <br>

    <p
      v-if="!politic"
      class="vLandingFeedback__error-text"
      style="font-size: 12px; line-height: 16px"
    >
      Для отправки формы согласитесь с условиями Пользовательского соглашения и
      Политикой обработки персональных данных
    </p>

    <button
      type="submit"
      class="vLandingRegistrationForm__btn"
    >
      Зарегистрироваться
    </button>

    <label
      class="vLandingRegistrationForm__conditions"
    ><input
       v-model="politic"
       type="checkbox"
       checked="checked"
       class="vLandingRegistrationForm__conditions-check"
     >
      <div class="vLandingRegistrationForm__conditions-text">
        <p>
          Согласен с условиями
          <a
            href="#conf"
            class="vLandingRegistrationForm__conditions-text-link"
            @click="togglePoliticModal"
          >
            Пользовательского соглашения и Политикой обработки персональных
            данных</a>
        </p>
      </div></label>
  </Form>
</template>

<script>
import vueRecaptcha from 'vue3-recaptcha2'
import { mapGetters, mapActions } from 'vuex'
import convertPhoneFormat from '@/utils/convertPhoneFormat'
import { Form } from 'vee-validate'
import vLandingInput from '@/components/landing/v-landing-input'
export default {
  name: 'VLandingRegistrationForm',
  components: {
    vLandingInput,
    Form,
    vueRecaptcha,
  },
  emits: ['toggleRegistrationModal'],
  data() {
    return {
      name: '',
      phone: '',
      email: '',
      name_shop: '',
      password: '',
      confirmPassword: '',
      politic: true,
      showRecaptcha: true,
      recaptchaVerifiedSuccess: false,
    }
  },
  computed: {
    ...mapGetters('user', ['USER_ERROR', 'GET_REGISTRATION_SUCCESS']),
    ...mapGetters('shops', ['SHOP_ERROR']),
  },
  // watch: {
  //   email: function (val) {
  //     this.ACTION_CLEAR_USER_ERRORS()
  //   }
  // },
  methods: {
    ...mapActions('feedback', ['CLOSE_ACCESS']),
    ...mapActions('user', [
      'CHECK_EMAIL',
      'SIGNUP',
      'ACTION_CLEAR_USER_ERRORS',
    ]),
    ...mapActions('shops', ['CHECK_SHOPS', 'CLOSE_REGISTRATION_ACCESS']),
    ...mapActions('modalPolitic', ['CHANGE_MODAL_POLITIC']),
    async checkForm() {
      console.log('checkForm')
      console.log('!this.name', !this.name)
      if (!this.name) {
        this.err = true
        return
      }

      await this.CHECK_EMAIL(this.email)
      // await this.CHECK_SHOPS(this.name_shop)
      if (
        this.USER_ERROR.length < 1 &&
        this.SHOP_ERROR.length < 1 &&
        this.politic &&
        this.recaptchaVerifiedSuccess
      ) {
        const data = {
          name: this.name,
          email: this.email,
          password: this.password,
          phone: convertPhoneFormat(this.phone),
          name_shop: this.name_shop,
        }

        ym(85537528, 'reachGoal', 'rega')

        await this.SIGNUP(data)
        if (this.GET_REGISTRATION_SUCCESS) {
          this.$emit('toggleRegistrationModal')
          setTimeout(() => {
            this.CLOSE_REGISTRATION_ACCESS()
            this.$router.push('/profile')
            this.CLOSE_ACCESS()
          }, 2000)
        }
      }
    },
    togglePoliticModal() {
      this.CHANGE_MODAL_POLITIC()
    },
    recaptchaVerified(response) {
      this.recaptchaVerifiedSuccess = true
    },
    recaptchaExpired() {
      this.$refs.vueRecaptcha.reset()
    },
  },
}
</script>

<style lang="scss">
.vLandingRegistrationForm {
  width: 100%;
  max-width: 301px;
  margin: 0 auto;
  &__btn {
    margin-top: 24px;
    margin-bottom: 12px;
    padding: 16px 0;
    width: 100%;
    font-weight: 500;
    font-size: 20px;
    line-height: 28px;
    color: #fbfbfc;
    background-color: #c01718;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    &:hover {
      background-color: #e01b1c;
    }
  }
  &__conditions {
    display: flex;
    align-items: center;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #e4e4eb;
    p {
      margin: 0;
    }
  }
  &__conditions-check {
    position: absolute;
    z-index: -1;
    opacity: 0;
    &:checked + .vLandingRegistrationForm__conditions-text::before {
      background-image: url(~@/assets/images/input_check.svg);
    }
  }
  &__conditions-text {
    display: inline-flex;
    user-select: none;
    &::before {
      content: '';
      display: inline-block;
      width: 14px;
      height: 14px;
      flex-shrink: 0;
      flex-grow: 0;
      border: 1.5px solid #dcdee3;
      border-radius: 0.4em;
      margin-right: 17px;
      margin-top: 6px;
      background-repeat: no-repeat;
      background-position: center center;
      background-size: 12px;
    }
  }
  &__conditions-text-link {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #e4e4eb;
  }
}
.error-wrap.active .vLandingInput__wrapper {
  background: #f8e0e0;
  border: 1px solid #ec4e4e;
}
.error-wrap.active span {
  padding-left: 12px;
  margin-top: 4px;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  color: #ec4e4e;
}
</style>
