<template>
  <section
    ref="reasons"
    class="vLandingReasons"
  >
    <div class="landing-container">
      <h2 class="vLandingReasons__title landing-section-title">
        3 главных причины внедрить Tapper
      </h2>
      <div class="vLandingReasons__content">
        <div
          class="vLandingReasons__iphones"
          :class="{ active: veerPhones }"
        >
          <img
            src="~@/assets/images/choice_payment.png"
            alt=""
            class="bottom-iphone vLandingReasons__iphone"
          >
          <img
            src="~@/assets/images/divideCheck.png"
            alt=""
            class="vLandingReasons__iphone"
          >
          <img
            src="~@/assets/images/payd_check.png"
            alt=""
            class="top-iphone vLandingReasons__iphone"
          >
        </div>
        <div class="vLandingReasons__text">
          <div class="vLandingReasons__text-block top-block">
            <h3 class="vLandingReasons__block-title">
              Скорость закрытия счёта
            </h3>
            <p class="vLandingReasons__block-text">
              Вы можете хорошо обслужить гостя, но если официант будет долго
              нести счет или терминал, то негатив перекроет все
            </p>
          </div>
          <div class="vLandingReasons__text-block middle-block">
            <h3 class="vLandingReasons__block-title">
              Возможность разделить счёт
            </h3>
            <p class="vLandingReasons__block-text">
              Любое количество гостей могут разделить и оплатить один счет, что
              сильно экономит всем время и нервы
            </p>
          </div>
          <div class="vLandingReasons__text-block bottom-block">
            <h3 class="vLandingReasons__block-title">
              Умные чаевые
            </h3>
            <p class="vLandingReasons__block-text">
              Оплата счета и чаевых происходит одним платежом. При этом мы
              единственные, кто переводит оплату на счет заведения, а чаевые -
              отдельно, к примеру на карту официанту
            </p>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'VLandingReasons',

  data() {
    return {
      veerPhones: false,
    }
  },
  mounted() {
    // Veer mobile
    document.addEventListener('scroll', this.veerHandler)
  },
  beforeUnmount() {
    document.removeEventListener('scroll', this.veerHandler)
  },
  methods: {
    veerHandler() {
      const position = this.$refs.reasons.offsetTop
      const scroll = document.documentElement.scrollTop
      scroll >= position ? (this.veerPhones = true) : (this.veerPhones = false)
    },
  },
}
</script>

<style lang="scss">
.vLandingReasons {
  padding: 100px 0;
  text-align: center;
  background: linear-gradient(94.19deg, #14141b 48.83%, #0c0c10 78.63%);
  @media (max-width: 991px) {
    padding: 70px 0 138px;
  }
  @media (max-width: 769px) {
    padding: 60px 0;
  }
  &__title {
    margin-bottom: 70px;
    @media (max-width: 991px) {
      margin-bottom: 86px;
    }
    @media (max-width: 769px) {
      margin-bottom: 78px;
    }
  }
  &__content {
    display: flex;
    justify-content: space-between;
    @media (max-width: 769px) {
      flex-direction: column;
    }
  }
  &__iphones {
    position: relative;
    min-width: 50%;
    margin-right: 20px;
    &.active {
      .bottom-iphone {
        transform: translateX(-27%) translateY(-45px) rotate(13.67deg);
        z-index: 1;
      }
      .top-iphone {
        transform: translateX(-65%) translateY(55px) rotate(-12.37deg);
        z-index: 3;
      }
    }
    @media (max-width: 769px) {
      margin-right: 0;
      margin-bottom: 78px;
    }
  }
  &__iphone {
    position: relative;
    width: 250px;
    z-index: 2;
    &.bottom-iphone {
      z-index: 1;
    }
    &.bottom-iphone,
    &.top-iphone {
      position: absolute;
      top: 0;
      left: 50%;
      transform: translateX(-50%);
      transition: transform 0.6s cubic-bezier(0.65, 0, 0.35, 1);
    }
    @media (max-width: 991px) {
      width: 230px;
    }
    @media (max-width: 769px) {
      width: 155px;
      height: auto;
    }
  }
  &__text {
    position: relative;
    max-width: 40%;
    @media (max-width: 769px) {
      max-width: 100%;
    }
  }
  &__text-block {
    padding: 28px 30px 60px;
    max-width: 446px;
    text-align: left;
    background-color: #fbfbfc;
    border: 1px solid #cbcfdc;
    box-shadow: 0px 4px 40px rgba(21, 23, 27, 0.15);
    border-radius: 15px;
    @media (max-width: 991px) {
      padding: 16px 25px 65px;
    }
    @media (max-width: 769px) {
      max-width: 100%;
      padding: 24px 16px;
      &:not(:last-child) {
        margin-bottom: 24px;
      }
    }
    &.middle-block {
      position: absolute;
      top: 185px;
      left: -80px;
      z-index: 2;
      @media (max-width: 1199px) {
        max-width: 381px;
      }
      @media (max-width: 991px) {
        padding: 16px 25px 65px;
      }
      @media (max-width: 769px) {
        position: static;
        max-width: 100%;
        padding: 24px 16px;
      }
    }
    &.bottom-block {
      position: absolute;
      top: 365px;
      left: 0;
      padding-bottom: 26px;
      z-index: 3;
      @media (max-width: 769px) {
        position: static;
      }
    }
  }
  &__block-title {
    margin-bottom: 30px !important;
    font-family: 'Stolzl';
    font-weight: 400;
    font-size: 20px;
    line-height: 28px;
    color: #14141b;
    @media (max-width: 991px) {
      margin-bottom: 20px !important;
      font-size: 18px;
    }
    @media (max-width: 769px) {
      margin-bottom: 24px !important;
      font-size: 22px;
    }
  }
  &__block-text {
    font-family: 'PTRootUI';
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #5a5a68;
    @media (max-width: 991px) {
      font-size: 16px;
    }
    @media (max-width: 769px) {
      font-size: 18px;
    }
  }
}
</style>
