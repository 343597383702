<template>
  <section class="vLandingMain">
    <div class="vLandingMain__container landing-container">
      <div class="vLandingMain__info">
        <h1 class="vLandingMain__title">
          Облачный сервис <br>
          для управления заказом в кафе и ресторанах
        </h1>
        <p class="vLandingMain__text">
          Увеличивает средний чек, чаевые и лояльность
        </p>
        <a
          v-scroll-to="'#vLandingQuestions__callback'"
          href="#"
          class="vLandingMain__connect-link"
        >
          Оставить заявку
          <svg
            width="16"
            height="14"
            viewBox="0 0 16 14"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M1 7.99997H12.17L8.29 11.88C7.9 12.27 7.9 12.91 8.29 13.3C8.68 13.69 9.31 13.69 9.7 13.3L15.29 7.70997C15.68 7.31997 15.68 6.68997 15.29 6.29997L9.71 0.699971C9.32 0.309971 8.69 0.309971 8.3 0.699971C7.91 1.08997 7.91 1.71997 8.3 2.10997L12.17 5.99997H1C0.45 5.99997 0 6.44997 0 6.99997C0 7.54997 0.45 7.99997 1 7.99997Z"
              fill="#FBFBFC"
            /></svg></a>
        <router-link
          to="/demo"
          class="vLandingMain__demo-link"
        >
          Смотреть демо-версию
        </router-link>
      </div>
      <div class="vLandingMain__main-demo">
        <img
          src="@/assets/images/Iphone-2.gif"
          alt="Демо-версия Tapper"
          class="vLandingMain__demo-iphone vLandingMain__demo-iphone--desktop"
        >
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'VLandingMain',
  emits: ['toggleRegistrationModal'],
  methods: {
    toggleRegistrationModal() {
      this.$emit('toggleRegistrationModal')
    },
  },
}
</script>

<style lang="scss">
.vLandingMain {
  position: relative;
  padding: 25px 0 30px;
  font-family: 'Stolzl';
  font-weight: 400;
  &::before {
    content: '';
    background: linear-gradient(
        68.93deg,
        #14141b 11.39%,
        rgba(20, 20, 27, 0) 100%
      ),
      url(~@/assets/images/bg_main.png) center/cover no-repeat;
    opacity: 0.7;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    position: absolute;
    z-index: 0;
  }
  @media (max-width: 769px) {
    padding: 2px 0 12px;
  }
  &__info {
    padding-top: 40px;
    z-index: 2;
    @media (max-width: 991px) {
      padding-top: 30px;
    }
  }
  &__title {
    margin-bottom: 32px;
    font-size: 56px;
    line-height: 82px;
    font-weight: 400;
    color: #fbfbfc;
    margin-top: 0;
    @media (max-width: 991px) {
      font-size: 34px;
      line-height: 60px;
    }
    @media (max-width: 769px) {
      margin-bottom: 40px;
      font-size: 29px;
      line-height: 38px;
    }
  }
  &__text {
    margin-bottom: 64px;
    font-family: 'PTRootUI';
    font-size: 52px;
    line-height: 64px;
    color: #e4e4eb;
    @media (max-width: 991px) {
      font-size: 26px;
      line-height: 50px;
    }
    @media (max-width: 769px) {
      margin-bottom: 40px;
      font-size: 22px;
      line-height: 30px;
    }
  }
  &__connect-link {
    display: inline-block;
    padding: 16px 28px;
    font-weight: 500;
    font-size: 20px;
    line-height: 28px;
    color: #fbfbfc;
    background-color: #c01718;
    border-radius: 8px;
    text-decoration: none;
    cursor: pointer;
    &:hover {
      background-color: #e01b1c;
    }
    @media (max-width: 991px) {
      padding: 16px 10px;
      font-size: 16px;
    }
    @media (max-width: 769px) {
      width: 100%;
      margin-bottom: 20px !important;
      text-align: center;
      font-size: 18px;
      line-height: 22px;
    }
  }
  &__demo-link {
    display: none;
    width: 100%;
    padding: 14px 15px;
    margin-bottom: 50px;
    font-family: 'Stolzl';
    font-weight: 500;
    font-size: 18px;
    line-height: 22px;
    text-align: center;
    color: #fbfbfc;
    border: 2px solid #9c1313;
    border-radius: 8px;
    text-decoration: none;
    @media (max-width: 769px) {
      display: inline-block;
      margin-bottom: 0;
    }
  }
  &__container {
    display: flex;
  }
  &__demo-iphone {
    width: 320px;
    @media (max-width: 991px) {
      width: 300px;
    }
    @media (max-width: 769px) {
      width: 50%;
    }
  }
  &__main-demo {
    z-index: 2;
    @media (max-width: 769px) {
      display: none;
    }
  }
}
</style>
